import "@aws-amplify/ui-react/styles.css";
import {
  withAuthenticator,
  Button,
  Heading,
  Image,
  View,
  Card,
} from "@aws-amplify/ui-react";
import { getCurrentUser } from 'aws-amplify/auth';
import React, { useEffect, useRef, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { apiGet, apiPost } from './common/func';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

// ------------------------ const ------------------------
const MSG = 'msg'
const ERR = 'err'

// ------------------------ var ------------------------
var user = null

// ------------------------ app ------------------------
function App({ signOut }) {
  // ------------------------ binding data ------------------------
  const [siteData, setSiteData] = useState({})
  const [init, setInit] = useState(true)

  // ------------------------ notification ------------------------
  const notify = (message, type) => {
    toast[type](message, {
      position: 'top-right',
      autoClose: 3000, // Duration in milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const notifySuccess = (msg) => {
    notify(msg, 'success');
  };

  const notifyError = (msg) => {
    notify(msg, 'error');
  };

  // ------------------------ functions ------------------------
  const initFunc = async () => {
    user = await getCurrentUser()
    await syncSiteInfo()
    setInit(false)
  };

  // ------------------------ ui actions ------------------------
  const listSites = () => {
    return Object.entries(siteData).map(([key, value]) => (
      <li className="list-group-item" key={key}>
        <h6 className="card-title">
          <span className={`mx-1 badge ${value.opCfg.enable ? 'bg-success' : 'bg-danger'}`}>
            {value.opCfg.enable ? 'Enabled' : 'Disabled'}
          </span>
          {value.pname}
        </h6>

        {/* enabled site */}
        <div className={`container ${value.opCfg.enable ? 'visible' : 'd-none my-0'}`}>
          <div class="row">
            <div class="col-md-auto px-0 align-self-center">
              <button type="button" className="btn btn-outline-danger btn-sm"
                onClick={() => disableHalClick(key)} eid={key}>
                Disable HAL Control
              </button>
            </div>
            <div class="col fw-light fst-italic px-2">
              <small>
                <span className="fw-bold">Caution!</span> Disabling HAL will return all zone setpoints to default static values.
                <br></br>
                Only HAL tech team members can restore HAL dynamic comfort.
              </small>
            </div>
          </div>
        </div>

        {/* disabled site */}
        <small className={`fw-light fst-italic ${value.opCfg.enable ? 'd-none' : 'visible'}`}>
          HAL dynamic comfort has been disabled in this building.
          <br></br>
          Please contact HAL tech team on 0400 223 518 or <a href="mailto: hal-tech@halsystems.com.au" class="link-primary">hal-tech@halsystems.com.au</a> to restore HAL dynamic comfort.
        </small>
      </li>
    ));
  };

  const syncSiteInfo = async () => {
    // get site info from db
    var resp = await apiGet(
      '/get-site-info/' + encodeURIComponent(user.username)
    );

    // convert site data to dict
    const siteDataNew = resp.msg.reduce((acc, item) => {
      acc[item.eid] = item;
      return acc;
    }, {});

    // update siteData
    setSiteData(siteDataNew)
  };

  const disableHalClick = async (key) => {
    var resp = await apiPost(
      '/disable-ai/' +

      encodeURIComponent(user.username) + '/' +
      encodeURIComponent(key), {
        'enable': false
      }
    );

    // display on notification
    if(MSG in resp){
      notifySuccess(
        <>
          <h6>{resp.msg}</h6>
          <small class="fw-lighter">It takes around 15 minutes to take effect</small>
        </>,
      )
      await syncSiteInfo()
    }

    if(ERR in resp)
      notifyError(resp.err)
  };

  // init
  useEffect(() => {
    // startup code
    initFunc()
  }, []);

  // ui
  return (
    <View className="App">
      {/* notification */}
      <ToastContainer />

      {/* top bar */}
      <nav className="navbar navbar-dark px-2" style={{backgroundColor: "#575b5e"}}>
        <img src="/logo192.png" width="35" height="35" alt=""></img>
        <div className="navbar-brand" href="#">HAL Portal</div>
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <Button className="btn btn-outline-light" onClick={signOut}>Sign Out</Button>
        </ul>
      </nav>

      {/* contact details  */}
      {/* <small class="d-flex justify-content-end mx-2 mt-1 fst-italic fw-light">
        Contact us @ 0400 223 518
      </small> */}

      {/* content  */}
      <div className="container col-lg-8">
        <div className="card mx-2 mt-4">
          <h5 className="card-header">
            Sites
          </h5>
          <ul className="list-group list-group-flush">
            {init?<li className="list-group-item text-center"><small class="fw-lighter fst-italic">{init?'loading...':''}</small></li>:null}
            {listSites()}
          </ul>
        </div>
      </div>

      {/* jason test*/}

    </View>
  );
}

export default withAuthenticator(App, { hideSignUp: true });